module.exports = {
  siteTitle: 'Niwx.Dev - Nothing to see here..', // <title>
  manifestName: 'Niwx.dev',
  manifestShortName: 'splash', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/null/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Niwx.Dev - Nothing to see here',
  subHeading:
    "Seriously, there's nothing here. Just stop looking, and don't bother clicking this button below..",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/niworx',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/niworx',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/niworx',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contact@niwx.dev',
    },
  ],
};
